import React, { useState } from 'react';

// Mock data for articles
const mockArticles = [
  {
    id: 1,
    title: "Luxury Market Trends 2024",
    preview: "The latest insights into Atlanta's luxury real estate market...",
    date: "Mar 15, 2024",
    author: "Sarah Johnson",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.

    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
    image: "/api/placeholder/800/400"
  },
  {
    id: 2,
    title: "Buckhead's Rising Property Values",
    preview: "An analysis of the unprecedented growth in Buckhead...",
    date: "Mar 14, 2024",
    author: "Michael Chen",
    content: `Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.

    Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.`,
    image: "/api/placeholder/800/400"
  },
  // Add more mock articles as needed
];

const News = () => {
  const [selectedArticle, setSelectedArticle] = useState(mockArticles[0]);

  return (
    <div className="relative w-full min-h-screen">
   

      {/* Content */}
      <div className="relative pt-16 pb-12 mx-auto max-w-7xl px-6 ">
        <div className="flex gap-6 h-[calc(100vh-8rem)]">
          {/* Left Column - Article List */}
          <div className="relative w-1/3 max-h-[640px]">
            <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg" />
            <div className="relative h-full flex flex-col overflow-hidden">
              <div className="p-4 border-b border-white/10">
                <h2 className="text-xl font-bold text-white">Latest News</h2>
              </div>
              <div className="flex-1 overflow-y-auto">
                {mockArticles.map((article) => (
                  <button
                    key={article.id}
                    onClick={() => setSelectedArticle(article)}
                    className={`w-full text-left p-4 border-b border-white/10 transition-colors
                              ${selectedArticle.id === article.id ? 'bg-white/10' : 'hover:bg-white/5'}`}
                  >
                    <h3 className="text-white font-semibold line-clamp-2">{article.title}</h3>
                    <p className="text-gray-300 text-sm mt-1 line-clamp-2">{article.preview}</p>
                    <div className="flex items-center gap-2 mt-2 text-gray-400 text-sm">
                      <span>{article.author}</span>
                      <span>•</span>
                      <span>{article.date}</span>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column - Article View */}
          <div className="relative flex-1 max-h-[640px]">
            <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg" />
            <div className="relative h-full flex flex-col overflow-hidden">
              {selectedArticle ? (
                <div className="flex-1 overflow-y-auto">
                  <div className="p-8">
                    <img
                      src={selectedArticle.image}
                      alt={selectedArticle.title}
                      className="w-full h-64 object-cover rounded-lg mb-6"
                    />
                    <h1 className="text-3xl font-bold text-white mb-4">
                      {selectedArticle.title}
                    </h1>
                    <div className="flex items-center gap-2 text-gray-300 mb-6">
                      <span className="font-medium">{selectedArticle.author}</span>
                      <span>•</span>
                      <span>{selectedArticle.date}</span>
                    </div>
                    <div className="prose prose-invert prose-lg">
                      {selectedArticle.content.split('\n\n').map((paragraph, idx) => (
                        <p key={idx} className="text-gray-300 mb-4">
                          {paragraph}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p className="text-white">Select an article to read</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;