import React, { useState } from 'react';

export const ZipCodeInput = ({ onCenterChange }) => {
    const [zip, setZip] = useState('');
    const [error, setError] = useState('');
  
    const handleZipSubmit = async (e) => {
      e.preventDefault();
      setError('');
  
      if (!zip.match(/^\d{5}$/)) {
        setError('Please enter a valid 5-digit zip code');
        return;
      }
  
      try {
        const geocoder = new window.google.maps.Geocoder();
        const response = await geocoder.geocode({ address: zip });
        
        if (response.results[0]) {
          const location = response.results[0].geometry.location;
          onCenterChange({ 
            lat: location.lat(), 
            lng: location.lng() 
          });
        } else {
          setError('Zip code not found');
        }
      } catch (error) {
        setError('Error finding location');
        console.error('Geocoding error:', error);
      }
    };
  
    return (
      <div className="p-6 h-full flex flex-col ">
        <form onSubmit={handleZipSubmit} className="space-y-4">
          <div className="flex flex-col space-y-2">
            <label htmlFor="zipcode" className="text-white text-lg font-semibold">
              Enter Zip Code
            </label>
            <input
              type="text"
              id="zipcode"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
              placeholder="Enter ZIP code"
              maxLength={5}
              className="px-4 py-2 bg-black/30 backdrop-blur-sm border border-white/20 rounded-lg 
                       text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                       focus:ring-white/25 transition-all"
            />
          </div>
          {error && (
            <p className="text-red-400 text-sm">{error}</p>
          )}
          <button
            type="submit"
            className="w-full px-4 py-2 bg-white/10 hover:bg-white/20 
                     backdrop-blur-sm border border-white/20 rounded-lg
                     text-white font-semibold transition-all"
          >
            Search Location
          </button>
        </form>
      </div>
    );
  };
  
  export default ZipCodeInput;