import React, { useState , useEffect} from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, useNavigate, useParams } from "react-router-dom";
import HomeValue from "./HomeValue";
import News from "./News";
import Contact from "./Contact";
import { SocialIcon } from 'react-social-icons'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline'
import MapComponent from "./MapComponent";
import ZipCodeInput from "./ZipCodeInput";
const solutions = [
  { name: 'Home Value Estimator', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
  { name: 'News', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
  { name: 'Contact', description: "Your customers' data will be safe and secure", href: '#', icon: FingerPrintIcon },
  
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

const items = [
  { 
    id: 1,
    image: 'h.jpeg',
    address: '1234 Market St. Atlanta, GA',
    status: 'SOLD',
    bedBathSqft: '4 Bed 4 Bath 4,500 SQFT',
    saleOrListPrice: '$435,000',
   },
  { 
    id: 2,
    image: 'h.jpeg',
    address: 'Address',
    status: '1234 Market St.',
    bedBathSqft: 'City',
    saleOrListPrice: 'San Francisco',
   },
  { 
    id: 3,
    image: 'h.jpeg',
    address: 'Address',
    status: '1234 Market St.',
    bedBathSqft: 'City',
    saleOrListPrice: 'San Francisco',
   },
  { 
    id: 4,
    image: 'h.jpeg',
    address: 'Address',
    status: '1234 Market St.',
    bedBathSqft: 'City',
    saleOrListPrice: 'San Francisco',
   },
  { 
    id: 5,
    image: 'h.jpeg',
    address: 'Address',
    status: '1234 Market St.',
    bedBathSqft: 'City',
    saleOrListPrice: 'San Francisco',
   },
  { 
    id: 6,
    image: 'h.jpeg',
    address: 'Address',
    status: '1234 Market St.',
    bedBathSqft: 'City',
    saleOrListPrice: 'San Francisco',
   },
]

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // const [message, setMessage] = useState("");
  // const handleRunFilter = async () => {
  //   const response = await fetch("/run_filter", { method: "POST" });
  //   const data = await response.json();
  //   setMessage(data.message);
  // };
  // var window_width = window.innerWidth;
  const images = [
    '/m1.jpg',
    '/m2.jpg',
    '/m3.jpg',
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);

      // After CSS transition duration, update the index
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsTransitioning(false);
      }, 1000); // Match with CSS duration
    }, 10000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const mapStyles = [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [{ color: "#242f3e" }]
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#242f3e" }]
    },
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [{ color: "#746855" }]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }]
    }
  ];
  return (
    <Router>
    <div >

      {/* sticky navbar */}
      {/* <StickyNavbar /> */}
      <nav
          className="px-8 fixed rounded-b-3xl left-0 right-0 top-0 w-full bg-black/50 shadow  backdrop-blur-lg z-[1000] mx-auto max-w-screen-3xl"
        >
                  <div class="container flex flex-wrap items-center justify-between mx-auto text-slate-800 py-2">
    <a href="/"
      class="block cursor-pointer  text-base text-white font-semibold">
      <img src="/LA.png"  style={{ height: 30  }} />
    </a>
    {windowWidth < 783 ? (
     <></>

   )
    
  : (
    <div class="block mr-8 lg:mr-0">
    <ul class="flex  gap-2  mb-0 mt-0 flex-row items-center gap-6">
      
      <li class="flex items-center text-sm gap-x-2 text-white">
        <a href="/" class="flex items-center">Explore</a>
      </li>
      <li class="flex items-center text-sm gap-x-2 text-white">
        <a href="/homevalue" class="flex items-center">Home Value Estimator</a>
      </li>
      <li class="flex items-center  text-sm gap-x-2 text-white">
        <a href="/news" class="flex items-center">News</a>
      </li>
      <li class="flex items-center text-sm gap-x-2 text-white">
        <a href="/contact" class="flex items-center">Contact</a>
      </li>
     
    </ul>
  </div>


  )
  
  }
  

  </div>
</nav>
{ windowWidth < 768 ? (
  <Popover className="fixed top-0 right-0 z-[10000] py-2 text-white ">
        <PopoverButton className="inline-flex mr-8 items-center gap-x-1 text-sm/6 font-semibold">
          <ChevronDownIcon aria-hidden="true" className="size-5 " />
        </PopoverButton>
  {/* testing runonsave */}
  {/* testing runonsave */}
        <PopoverPanel
          transition
          className="  mt-1.5 absolute right-0 z-10  mr-6 flex w-screen max-w-max   transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-300 data-[enter]:ease-in data-[leave]:ease-out"
        >
          <div className="  flex-auto  bg-black/40 backdrop-blur-lg rounded-b-2xl text-sm/6 shadow-md  ">
            <div className="p-4">
              {solutions.map((item) => (
                <div key={item.name} 
                className="group relative flex rounded-lg px-3 py-2 hover:bg-black/10 text-right">                  
                  <div>
                    <a href={item.href} className="font-semibold   ">
                      {item.name}
                      {/* <span className="absolute inset-0" /> */}
                    </a>
                    {/* <p className="mt-1 ">{item.description}</p> */}
                  </div>
                </div>
              ))}
            </div>
          
          </div>
        </PopoverPanel>
      </Popover>
  )
  : (
    <></>
    )}

<div
        className={`absolute top-0 left-0 w-full h-full bg-cover bg-center transition-opacity duration-1000 ${
          isTransitioning ? "opacity-0" : "opacity-100"
        }`}
        style={{
          backgroundImage: `url(${images[currentImageIndex]})`,
        }}
      />
      {/* Next image (preloaded for smooth transition) */}
      {/* <div
        className={`absolute top-0 left-0 w-full h-full bg-cover bg-center transition-opacity duration-1000 ${
          isTransitioning ? "opacity-100" : "opacity-0"
        }`}
        style={{
          backgroundImage: `url(${images[(currentImageIndex + 1) % images.length]})`,
        }}
      /> */}
      {/* <video
        className="fixed top-0 left-0 w-full h-full object-cover z-[-1]"
        loop
        autoPlay
        muted
        playsInline
      >
        <source
          src="https://docs.material-tailwind.com/demo.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black/30">
      
      </div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/homevalue" element={<HomeValue />} />
      </Routes>


      <footer class="w-full px-8 rounded-t-3xl bg-black  py-4  max-w-screen-3xl mx-auto fixed bottom-0 left-0 right-0 bg-opacity-50 backdrop-blur-lg">
      
      <div class="container flex flex-wrap items-center justify-between mx-auto text-slate-800  ">
        <div class="flex  ">
      <a href="#" class="text-white  ">
      <img src="/bhhsga.png"   style={{ height: 28  }} />

      </a>
      {/* <a href="#" class="text-white ">
      <SocialIcon network="instagram" bgColor="transparent" style={{ height: 35, width:35 ,   }} />

      </a>
      <a href="#" class="text-white">
      <SocialIcon network="tiktok"  bgColor="transparent" style={{ height: 35, width:35 ,   }} />

      </a>
      <a href="#" class="text-white">

      </a> */}
    </div>

{/* if mobile viewing, make text different */} 
{  windowWidth < 768 ? (
  <p class="text-sm text-white text-center   ">
  © 2024 Luxury Atlanta
</p>
) :
<p class="text-sm text-white text-center   ">
Copyright © 2024 Luxury Atlanta
</p>

}

  

{windowWidth < 768 ? (
            <p class="text-sm text-white text-right right-14 absolute">
            Terms
          </p>
    )
    
  : (
    <>
     {/* <p class="text-sm text-white text-right right-14 absolute">
     Newsletter
    </p> */}
    
    </>
  )}
  </div>
  </footer>
    </div>
  </Router>
  );
}










const Home = () => {
  const [mapCenter, setMapCenter] = useState({ lat: 33.7490, lng: -84.3880 });
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);
  


  return (
      <div >
        <div className="relative w-full h-screen">
       

      <div className=" pt-8 lg:py-16 mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8  ">
       
        <div className=" grid grid-cols-1 gap-4   lg:grid-cols-6 grid-rows-10 lg:grid-rows-3 max-h-[670px]">
        
        <div className="relative lg:col-span-2 row-span-2">
            <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg " />
            <div className="relative flex h-full flex-col overflow-hidden ">
             
            <ZipCodeInput onCenterChange={setMapCenter} />

           
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg   " />
          </div>

          <div className="relative lg:col-span-2 row-span-2">
            <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg " />
            <div className="relative flex h-full flex-col overflow-hidden ">
 
            <MapComponent center={mapCenter} />

            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg   " />
          </div>

          <div className="relative lg:col-span-2 row-span-2">
  <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg" />
  <div className="relative flex h-full flex-col overflow-hidden">
    {/* Scrollable container with hidden scrollbar */}
    <div className="flex-1 overflow-y-auto scrollbar-hide mx-4">
      <ul role="list" className="divide-y divide-gray-200">
        {items.map((item) => (
          <li key={item.id} className="py-4 flex items-center">
            {/* Image */}
            <div className="w-16 h-16 flex-shrink-0">
              <img
                src={item.image}
                alt={item.imageAlt || ''}
                className="h-full w-full rounded object-cover"
              />
            </div>
            {/* Text content */}
            <div className="ml-4 flex-1">
              {/* First row */}
              <div className="flex justify-between">
                <span className="text-sm font-medium ">{item.address}</span>
                <span className="text-sm ">{item.status}</span>
              </div>
              {/* Second row */}
              <div className="flex justify-between mt-1">
                <span className="text-sm ">{item.bedBathSqft}</span>
                <span className="text-sm ">{item.saleOrListPrice}</span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
</div>


          <div className="relative lg:col-span-3 ">
            <div className="absolute inset-px rounded-lg bg-black/40 backdrop-blur-lg  " />
            <div className="relative flex h-full flex-col overflow-hidden ">
           
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg " />
          </div>
          <div className="relative lg:col-span-3">
            <div className="absolute inset-px rounded-lg bg-black/40 backdrop-blur-lg " />
            <div className="relative flex h-full flex-col overflow-hidden  ">
          
              <div className="p-10 pt-4">
                <h3 className="text-sm/4 font-semibold text-indigo-600">Releases</h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">Push to deploy</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  Curabitur auctor, ex quis auctor venenatis, eros arcu rhoncus massa, laoreet dapibus ex elit vitae
                  odio.
                </p>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg " />
          </div>
       
        </div>
      </div>
      
    </div>

  </div>
);
}

export default App;

