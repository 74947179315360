import React, { useState } from 'react';

const HomeValue = () => {

  return (
    <div>
      <div className="relative w-full">
    
        {/* Main content */}
        <div className=" pt-8 lg:py-16 mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8  ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 max-h-[800px] ">
            {/* Top Left: Property Information Input */}
            <div className="relative">
              <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg" />
              <div className="relative h-full flex flex-col overflow-hidden p-6">
                <h2 className="text-2xl font-bold text-white mb-6">Property Details</h2>
                <form className="space-y-4">
                  <div className="flex flex-col space-y-2">
                    <label className="text-white">Address</label>
                    <input
                      type="text"
                      className="px-4 py-2 bg-black/30 backdrop-blur-sm border border-white/20 rounded-lg 
                               text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                               focus:ring-white/25 transition-all"
                      placeholder="Enter property address"
                    />
                  </div>
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <label className="text-white">Bedrooms</label>
                      <input
                        type="number"
                        className="w-full px-4 py-2 bg-black/30 backdrop-blur-sm border border-white/20 rounded-lg 
                                 text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                                 focus:ring-white/25 transition-all"
                      />
                    </div>
                    <div className="flex-1">
                      <label className="text-white">Bathrooms</label>
                      <input
                        type="number"
                        className="w-full px-4 py-2 bg-black/30 backdrop-blur-sm border border-white/20 rounded-lg 
                                 text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                                 focus:ring-white/25 transition-all"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="text-white">Square Footage</label>
                    <input
                      type="number"
                      className="w-full px-4 py-2 bg-black/30 backdrop-blur-sm border border-white/20 rounded-lg 
                               text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                               focus:ring-white/25 transition-all"
                    />
                  </div>
                </form>
              </div>
            </div>

            {/* Top Right: Map View */}
            <div className="relative">
              <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg" />
              <div className="relative h-full flex flex-col overflow-hidden">
                {/* <MapComponent center={mapCenter} /> */}
              </div>
            </div>

            {/* Bottom Left: Comparable Properties */}
            <div className="relative">
              <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg" />
              <div className="relative h-full flex flex-col overflow-hidden p-6">
                <h2 className="text-2xl font-bold text-white mb-4">Comparable Properties</h2>
                <div className="flex-1 overflow-y-auto scrollbar-hide">
                  <div className="space-y-4">
                    {[1, 2, 3].map((item) => (
                      <div key={item} className="flex items-center space-x-4 p-4 bg-black/20 rounded-lg">
                        <div className="w-20 h-20">
                          <img 
                            src="/api/placeholder/80/80" 
                            alt="Property"
                            className="w-full h-full object-cover rounded-lg"
                          />
                        </div>
                        <div className="flex-1">
                          <p className="text-white font-medium">123 Example St</p>
                          <p className="text-gray-300">4 beds, 3 baths</p>
                          <p className="text-gray-300">2,400 sqft</p>
                        </div>
                        <div className="text-right">
                          <p className="text-white font-bold">$850,000</p>
                          <p className="text-gray-300">Sold 2 mo ago</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Bottom Right: Value Analysis */}
            <div className="relative">
              <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg" />
              <div className="relative h-full flex flex-col overflow-hidden p-6">
                <h2 className="text-2xl font-bold text-white mb-4">Value Analysis</h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-lg text-white font-semibold mb-2">Estimated Value Range</h3>
                    <div className="bg-black/20 rounded-lg p-4">
                      <div className="flex justify-between items-center">
                        <span className="text-gray-300">Low</span>
                        <span className="text-white font-bold">$800,000</span>
                      </div>
                      <div className="flex justify-between items-center mt-2">
                        <span className="text-gray-300">High</span>
                        <span className="text-white font-bold">$950,000</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-lg text-white font-semibold mb-2">Market Trends</h3>
                    <div className="bg-black/20 rounded-lg p-4">
                      <p className="text-gray-300">
                        Property values in this area have increased by 8% over the last 12 months.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeValue;