import React, { useState } from 'react';
import { PhoneIcon, ChatBubbleLeftRightIcon, EnvelopeIcon } from '@heroicons/react/24/outline';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="relative w-full min-h-screen">
    

      <div className="relative pt-20 pb-20 mx-auto max-w-7xl px-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Left side - Contact Form */}
          <div className="relative">
            <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg" />
            <div className="relative p-6">
              <h2 className="text-2xl font-bold text-white mb-6">Send a Message</h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="name" className="block text-white mb-2">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-black/30 backdrop-blur-sm border border-white/20 rounded-lg 
                             text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                             focus:ring-white/25 transition-all"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-white mb-2">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-black/30 backdrop-blur-sm border border-white/20 rounded-lg 
                             text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                             focus:ring-white/25 transition-all"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="block text-white mb-2">Phone (optional)</label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-black/30 backdrop-blur-sm border border-white/20 rounded-lg 
                             text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                             focus:ring-white/25 transition-all"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-white mb-2">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows={4}
                    className="w-full px-4 py-2 bg-black/30 backdrop-blur-sm border border-white/20 rounded-lg 
                             text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                             focus:ring-white/25 transition-all"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-white/10 hover:bg-white/20 
                           backdrop-blur-sm border border-white/20 rounded-lg
                           text-white font-semibold transition-all"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>

          {/* Right side - Alternative Contact Methods */}
          <div className="relative">
            <div className="absolute inset-px rounded-lg bg-black/50 backdrop-blur-lg" />
            <div className="relative p-6">
              <h2 className="text-2xl font-bold text-white mb-6">Get in Touch</h2>
              
              <div className="space-y-6 ">
                {/* Phone Call Option */}
                <div className="bg-black/30 rounded-lg p-6 hover:bg-black/40 transition-colors">
                  <div className="flex  space-x-4">
                    <div>
                      <h3 className="text-lg font-semibold text-white">Phone</h3>
                      <p className="text-gray-300 mt-1">Available 9 AM - 9 PM EST</p>
                      <a 
                        href="tel:4807988807" 
                        className="text-white hover:underline mt-2 inline-block"
                      >
                        (480) 798-8807
                      </a>
                    </div>
                  </div>
                </div>

                {/* Text Message Option */}
                {/* <div className="bg-black/30 rounded-lg p-6 hover:bg-black/40 transition-colors">
                  <div className="flex items-center space-x-4">
                    <ChatBubbleLeftRightIcon className="h-8 w-8 text-white" />
                    <div>
                      <h3 className="text-lg font-semibold text-white">Text Message</h3>
                      <p className="text-gray-300 mt-1">Quick responses during business hours</p>
                      <a 
                        href="sms:4807988807" 
                        className="text-white hover:underline mt-2 inline-block"
                      >
                        (480) 798-8807
                      </a>
                    </div>
                  </div>
                </div> */}

                {/* Email Option */}
                <div className="bg-black/30 rounded-lg p-6 hover:bg-black/40 transition-colors">
                  <div className="flex  space-x-4">
                    <div>
                      <h3 className="text-lg font-semibold text-white">Email</h3>
                      <p className="text-gray-300 mt-1">For detailed inquiries</p>
                      <a 
                        href="mailto:contact@luxuryatlanta.com" 
                        className="text-white hover:underline mt-2 inline-block"
                      >
                        contact@luxuryatlanta.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;